<template>
  <div class="lesson-container">
    <DialogPage
      :bgImg="bgImg"
      :pageInfoList="pageInfoList"
      :buttonList="buttonList"
      @clickTheBubble="clickTheBubble"
      :clickIdList="clickIdList"
      :lessonNo="19"
    />
  </div>
</template>

<script>
import DialogPage from "@/components/Course/CoursePage/DialogPage";
export default {
  data() {
    return {
      clickIdList:[1,4,5,6],
      bgImg: require("@/assets/img/03-Backgrounds/level-1-background-blue.svg"),
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-19/menu-1-chenglong.svg"),
          isCircle: true,
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-19/menu-2-zhangziyi.svg"),
          isCircle: true,
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G01-hi/lesson-19/menu-3-yaoming.svg"),
          isCircle: true,
          startNumberRange: 3,
          endNumberRange: 3,
        },
      ],
      pageInfoList: [
        {
          index: 1,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-19/image-1-chenglong.svg"),
          singlePageList: [
            {
              id: 1,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-19/bubble-1-answer.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-19/bubble-1-question.svg"),
              isShowAnser: false,
            },
            {
              id: 2,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-19/bubble-1-wo-jiao-chenglong.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-19/bubble-1-wo-jiao-chenglong.svg"),
              isShowAnser: false,
            },
          ],
        },
        {
          index: 2,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-19/image-2-zhangziyi.svg"),
          singlePageList: [
            {
              id: 3,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-19/bubble-2-ni-jiao-shenme.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-19/bubble-2-ni-jiao-shenme.svg"),
              isShowAnser: false,
            },
            {
              id: 4,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-19/bubble-2-answer.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-19/bubble-2-question.svg"),
              isShowAnser: false,
            },
          ],
        },
        {
          index: 3,
          bgImg: require("@/assets/img/16-GAMES/G01-hi/lesson-19/image-3-yaoming.svg"),
          singlePageList: [
            {
              id: 5,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-19/bubble-3-answer-1.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-19/bubble-3-question-1.svg"),
              isShowAnser: false,
            },
            {
              id: 6,
              bubbleAnswer: require("@/assets/img/16-GAMES/G01-hi/lesson-19/bubble-3-answer-2.svg"),
              bubbleQuestion: require("@/assets/img/16-GAMES/G01-hi/lesson-19/bubble-3-question-2.svg"),
              isShowAnser: false,
            },
          ],
        },
      ],
    };
  },
  components: {
    DialogPage,
  },
  methods: {
    clickTheBubble(index, id) {
      this.pageInfoList[index - 1].singlePageList.forEach((item) => {
        if (item.id === id) {
          item.isShowAnser = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.lesson-container {
  width: 100%;
  height: 100%;
}
</style>