<template>
  <div class="game-container">
    <ClickImgToShowSentenceGame
      :bgImg="bgImg"
      :optionList="optionList"
      :maskImgList="maskImgList"
      @showCoeerctImg="showCoeerctImg"
      :titleInfo="titleInfo"
      :lessonNO="26"
    />
  </div>
</template>

<script>
import ClickImgToShowSentenceGame from "@/components/Course/GamePage/ClickImgToShowSentenceGame";
export default {
  data() {
    return {
      titleInfo: {
        pinyin: "zǎoshang,xiàwǔ,wǎnshang",
        hanzi: "早上、下午、晚上",
        position: "right"
      },
      bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/background.svg"),
      optionList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/1-found.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/1-found.svg"),
          id: 1,
          isShowCorrect: false,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/2-found.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/2-found.svg"),
          id: 2,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/3-found.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/3-found.svg"),
          id: 3,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/4-found.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/4-found.svg"),
          id: 4,
        },
      ],
      maskImgList: [
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/image-1.svg"),
          id: 1,
          centencePinyin: "Tā zǎoshang qī diǎn qǐchuáng.",
          sentenceHanzi: " 她早上7点起床。",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/image-2.svg"),
          id: 2,
          centencePinyin: "Tā zǎoshang bā diǎn sānshí fēn qù xuéxiào.",
          sentenceHanzi: "她早上8点30分去学校。",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/image-3.svg"),
          id: 4,
          centencePinyin: "Tā xiàwǔ sān diǎn qù gōngyuán.",
          sentenceHanzi: "她下午3点去公园。",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/image-4.svg"),
          id: 3,
          centencePinyin: "Tā wǎnshang qī diǎn chī miàntiáor.",
          sentenceHanzi: "她晚上7点吃面条儿。",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-26/image-4.svg"),
          id: 5,
          centencePinyin: "Tā wǎnshang jiǔ diǎn shuìjiào.",
          sentenceHanzi: "她晚上9点睡觉。",
        },
      ],
    };
  },
  components: {
    ClickImgToShowSentenceGame,
  },
  methods: {
    showCoeerctImg(index) {
      this.optionList[index - 1].isShowCorrect = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
