<template>
  <div class="wordscard-container layout-border">
    <WordCards
      :wordCardList="wordCardList"
      @changeDisplay="handleChangeDisplay"
    />
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: "redColor",
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "Xiànzài shì wǎnshang shíyī diǎn.",
          hanzi: "现在是晚上11点。",
          english: "I'm sorry",
          image: require(`@/assets/img/12-Vocabulary/L2/lesson-03-duibuqi.svg`),
          longtext: true,
        },
        {
          bgColor: "redColor",
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "Xiànzài shì wǎn shang qī diǎn shí fēn. ",
          hanzi: "现在是晚上7点10分。",
          english: "never mind",
          image: require(`@/assets/img/12-Vocabulary/L2/lesson-03-meiguanxi.svg`),
          longtext: true,
        },
        {
          bgColor: "redColor",
          order: "three",
          isShowImg: "true",
          currentNum: 2,
          pinyin: "Xiànzài shì wǎnshang jiǔ diǎn sìshíwǔ fēn. ",
          hanzi: "现在是晚上9点45分",
          english: "never mind",
          image: require(`@/assets/img/12-Vocabulary/L2/lesson-03-meiguanxi.svg`),
          longtext: true,
        },
      ],
    };
  },
  methods: {
    handleChangeDisplay(index, isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg;
    },
  },
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
