<template>
  <div class="wordscard-container layout-border">
    <WordCards :wordCardList="wordCardList" @changeDisplay="handleChangeDisplay" :lessonNum="11"/>
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: 'redColor',
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "zǎoshang",
          hanzi: "早上",
          english: "(in the) morning",
          image: require(`@/assets/img/12-Vocabulary/L2/lesson-09-zaoshang-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "wǎnshang",
          hanzi: "晚上",
          english: "(in the) evening; (at) night",
          image: require(`@/assets/img/12-Vocabulary/L2/lesson-09-wanshang-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          pinyin: "qǐchuáng",
          hanzi: "起床",
          english: "to get up",
          image: require(`@/assets/img/12-Vocabulary/L2/lesson-09-qichuang-bubble.svg`),
          blue: true,
        },
        {
          bgColor: 'redColor',
          order: "two",
          isShowImg: "true",
          currentNum: 2,
          pinyin: "shuìjiào",
          hanzi: "睡觉",
          english: "to sleep; to go to bed",
          image: require(`@/assets/img/12-Vocabulary/L2/lesson-09-shuijiao-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "four",
          isShowImg: "true",
          currentNum: 4,
          pinyin: "xiàwǔ",
          hanzi: "下午",
          english: "(in the) afternoon",
          image: require(`@/assets/img/12-Vocabulary/L2/lesson-09-shuijiao-bubble.svg`),
        },
      ],
    };
  },
  methods:{
    handleChangeDisplay(index,isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg
    }
  }
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
