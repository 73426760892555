<template>
  <div class="target-container">
    <StartStop :position="positionStr" :pageInfo="pageInfoObj" :chapterNo="2" />
  </div>
</template>

<script>
import StartStop from "@/components/Course/CoursePage/StartStop";

export default {
  data() {
    return {
      positionStr: "begin",
      pageInfoObj: {
        pinyin: "Xiànzài búshì xiàwǔ 3 diǎn (xià)",
        hanzi: "现在不是下午三点。（上）",
        english: "It's Not 3 PM Now (II)",
      },
    };
  },
  components: {
    StartStop,
  },
};
</script>

<style lang="scss" scoped>
.target-container {
  width: 100%;
  height: 100%;
}
</style>