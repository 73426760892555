<template>
  <div class="singpage-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage :singList="singList" :songInfo="songInfo" :timeStampList="timeStampList" :isReviewPage="isReviewPage"></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-73.mp3"),
      },
      timeStampList: [4.6, 6.8, 9.1, 11.7, 23.7, 25.8 ,28.3, 30.9],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Zǎoshang,zǎoshang,zǎoshang bā diǎn,",
            chineseWords: "早上，早上，早上8点",
          },
          {
            id: 2,
            spelling: "wǒmen zǎoshang bā diǎn qǐchuáng.",
            chineseWords: "我们早上8点起床。",
          },
          {
            id: 3,
            spelling: "Xiàwǔ,xiàwǔ,xiàwǔ sān diǎn,",
            chineseWords: "下午，下午，下午3点",
          },
          {
            id: 4,
            spelling: "wǒmen xiàwǔ sān diǎn qù gōng yuán.",
            chineseWords: "我们下午3点 去公园。",
          },
          {
            id: 5,
            spelling: "Wǎnshang,wǎnshang ,wǎnshang jǐ diǎn？",
            chineseWords: "晚上，晚上，晚上几点？",
          },
          {
            id: 6,
            spelling: "Nǐmen wǎnshang jǐ diǎn shuìjiào ？",
            chineseWords: "你们晚上几点睡觉？",
          },
          {
            id: 7,
            spelling: "Wǎnshang,wǎnshang ,wǎnshang jiǔ diǎn,",
            chineseWords: "晚上，晚上，晚上9点。",
          },
          {
            id: 8,
            spelling: "Wǒmen wǎnshang jiǔ diǎn shuìjiào.",
            chineseWords: "我们晚上9点睡觉。",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
